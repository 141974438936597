<template>
  <main class="d-flex w-100 mt-3">
    <div class="container d-flex flex-column">
      <div class="row justify-content-center mt-5">
        <div class="col text-center">
          <img src="../assets/images/Logo-Amanthy.png" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100 mt-5">
          <div class="d-table-cell">
            <div class="text-center">
              <login-form />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import LoginForm from "../components/Login.vue";

export default {
  name: "Access",

  components: { LoginForm },
  data() {
    return {};
  },
};
</script>
