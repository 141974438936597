<template>
  <div class="card">
    <div class="card-body">
      <h3 class="card-title">Accedi al tuo profilo</h3>

      <div class="alert alert-danger" v-if="isSubmitted">
        {{ submitResult ? "" : axiosErrorMessage }}
      </div>
      <el-form
        @submit.prevent="onSubmit"
        ref="loginForm"
        class="login-form"
        autocomplete="on"
        label-position="top"
      >
        <el-form-item
          prop="username"
          label="Username"
          :error="usernameField.errorMessage"
        >
          <el-input
            ref="username"
            :model-value="usernameField.value"
            @update:model-value="usernameField.handleChange"
            placeholder="Username"
            name="username"
            type="text"
            tabindex="1"
            autocomplete="on"
          />
        </el-form-item>

        <el-tooltip
          v-model="capsTooltip"
          content="Caps lock is On"
          placement="right"
          manual
        >
          <el-form-item
            prop="password"
            label="Password"
            :error="passwordField.errorMessage"
          >
            <el-input
              :key="passwordType"
              ref="password"
              @update:model-value="passwordField.handleChange"
              :model-value="passwordField.value"
              :type="passwordType"
              placeholder="Password"
              name="password"
              tabindex="2"
              autocomplete="on"
              @keyup="checkCapslock"
              @blur="capsTooltip = false"
            />
            <span class="show-pwd" @click="showPwd">
              <font-awesome-icon
                :icon="passwordType === 'password' ? eye : eyeClosed"
              />
            </span>
          </el-form-item>
        </el-tooltip>
        <el-button
          :loading="loading"
          type="primary"
          native-type="submit"
          style="width: 100%; margin-bottom: 30px"
          >Login</el-button
        >
      </el-form>

      <div class="row">
        <div class="col">
          <el-button type="text" @click="toPasswordForgot">
            Password dimenticata?
          </el-button>
          <el-button type="text" @click="toRegister">
            Registrati
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useField, useForm } from "vee-validate";
import { reactive } from "vue";
import { Login } from "../api/login";
import { mapGetters } from "vuex";

export default {
  name: "LoginForm",
  components: { FontAwesomeIcon },
  computed: { ...mapGetters(["role"]) },
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();

    const usernameField = reactive(useField("username"));
    const passwordField = reactive(useField("password"));
    return {
      formMeta,
      handleSubmit,
      resetForm,
      handleReset,
      usernameField,
      passwordField,
    };
  },

  data() {
    return {
      isSubmitted: false,
      submitResult: false,
      onSubmit: this.handleSubmit(this.handleLogin),
      axiosErrorMessage: "",
      eye: faEye,
      eyeClosed: faEyeSlash,
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
    };
  },

  methods: {
    toPasswordForgot() {
      this.$router.push({ path: "/passwordDimenticata" });
    },
    toRegister() {
      this.$router.push({ path: "/register" });
    },
    checkCapslock(e) {
      const { key } = e;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    async handleLogin(formValues) {
      this.loading = true;
      this.isSubmitted = false;
      await Login(formValues)
        .then((response) => {
          this.$store.dispatch("user/login", response.data).then(() => {
            if (this.role == "User") {
              this.$router.push({ path: "/dashboard" });
			} else if ( (this.role == "Admin") || (this.role == "President") ) {
              this.$router.push({ path: "/dashboardAdmin" });
            }
          });
        })
        .catch(() => {
          this.isSubmitted = true;
          this.axiosErrorMessage = "L'username o la password sono errati";
        });

      this.loading = false;
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
  },
};
</script>
<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.show-pwd {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 22px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
</style>
